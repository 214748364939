// 
// THIS FILE HAS BEEN GENERATED AUTOMATICALLY
// DO NOT CHANGE IT MANUALLY UNLESS YOU KNOW WHAT YOU'RE DOING
// 
// GENERATED USING @colyseus/schema 2.0.7
// 

import { Schema, type, ArraySchema, MapSchema, SetSchema, DataChange } from '@colyseus/schema';


export class Player extends Schema {
    @type("string") public id!: string;
    @type("string") public username!: string;
    @type("number") public progress!: number;
    @type("number") public wpm!: number;
    @type("number") public accuracy!: number;
    @type("boolean") public hasLeft!: boolean;
    @type("boolean") public isCompleted!: boolean;
}
