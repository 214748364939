import { Options } from '@hey-api/client-fetch';

import { ApiError } from '@Api/ApiError';
import CasualMatchModel from '@Api/models/CasualMatchModel';
import CasualMatchResultModel from '@Api/models/CasualMatchResultModel';
import ValidationErrorModel from '@Api/models/ValidationErrorModel';
import { useApi } from '@Api/useApi';

import {
  CasualMatchResponse,
  CasualMatchResultResponse,
  createCasualMatch,
  type CreateCasualMatchData,
  createCasualMatchResult,
  type CreateCasualMatchResultData,
  listCasualMatchResultsRanking,
  type ListCasualMatchResultsRankingData,
  type ListCasualMatchResultsRankingResponse,
} from '@Api-generated';

const hydrateMultiplayerMatch = (apiResponse: CasualMatchResponse): CasualMatchModel => {
  return CasualMatchModel.hydrateFromApi(apiResponse);
};

const hydrateCasualMatchResult = (apiResponse: CasualMatchResultResponse): CasualMatchResultModel => {
  return CasualMatchResultModel.hydrateFromApi(apiResponse);
};

const hydrateCasualMatchResults = (response: { data: CasualMatchResultResponse[] }): CasualMatchResultModel[] => {
  return response.data.map((competitionResult) => hydrateCasualMatchResult(competitionResult));
};

export function useCreateCasualMatch(
  onSuccess?: (result: CasualMatchModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<CreateCasualMatchData>, CasualMatchModel, CasualMatchResponse>(
    createCasualMatch,
    hydrateMultiplayerMatch,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useCreateCasualMatchResult(
  onSuccess?: (result: CasualMatchResultModel) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<Options<CreateCasualMatchResultData>, CasualMatchResultModel, CasualMatchResultResponse>(
    createCasualMatchResult,
    hydrateCasualMatchResult,
    onSuccess,
    onError,
    onValidationError
  );
}

export function useListCasualMatchResultsRanking(
  onSuccess?: (result: CasualMatchResultModel[]) => void,
  onError?: (error: ApiError) => void,
  onValidationError?: (validationError: ValidationErrorModel) => void
) {
  return useApi<
    Options<ListCasualMatchResultsRankingData>,
    CasualMatchResultModel[],
    ListCasualMatchResultsRankingResponse
  >(listCasualMatchResultsRanking, hydrateCasualMatchResults, onSuccess, onError, onValidationError);
}
