import CasualMatchModel from '@Api/models/CasualMatchModel';
import TestResultModel from '@Api/models/TestResultModel';
import UserModel from '@Api/models/UserModel';

import { CasualMatchResultResponse } from '@Api-generated';

import { Word } from '@Store/useTypingStore.types';

export default class CasualMatchResultModel implements CasualMatchResultResponse {
  public id: string;
  public createdAt: string;
  public updatedAt: string;
  public isLocked: boolean;
  public testResult: TestResultModel;
  public position: number;
  public casualMatch: CasualMatchModel;
  public user: UserModel;
  public words?: Word[];

  constructor(
    id: string,
    createdAt: string,
    updatedAt: string,
    isLocked: boolean,
    testResult: TestResultModel,
    position: number,
    casualMatch: CasualMatchModel,
    user: UserModel,
    words?: Word[]
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.isLocked = isLocked;
    this.testResult = testResult;
    this.position = position;
    this.casualMatch = casualMatch;
    this.user = user;
    this.words = words;
  }

  public static hydrateFromApi(casualMatchResultResponse: CasualMatchResultResponse): CasualMatchResultModel {
    const casualMatchModel = CasualMatchModel.hydrateFromApi(casualMatchResultResponse.casualMatch);
    const userModel = UserModel.hydrateFromApi(casualMatchResultResponse.user);
    const testResultModel = TestResultModel.hydrateFromApi(casualMatchResultResponse.testResult);

    let words = undefined;
    if (casualMatchResultResponse.words) {
      words = casualMatchResultResponse.words as Word[];
    }

    return new CasualMatchResultModel(
      casualMatchResultResponse.id,
      casualMatchResultResponse.createdAt,
      casualMatchResultResponse.updatedAt,
      casualMatchResultResponse.isLocked,
      testResultModel,
      casualMatchResultResponse.position,
      casualMatchModel,
      userModel,
      words
    );
  }
}
