import LanguageModel from '@Api/models/LanguageModel';
import UserModel from '@Api/models/UserModel';

import { CasualMatchResponse } from '@Api-generated';

import { CasualMatchStatus } from '@Components/helper/colyseus/colyseusHelper';

export default class CasualMatchModel implements CasualMatchResponse {
  public id: string;
  public urlHash: string;
  public createdAt: string;
  public updatedAt: string;
  public words: string;
  public isPrivate: boolean;
  public status: CasualMatchStatus;
  public participants: number;
  public creator: UserModel;
  public language: LanguageModel;
  public winner: UserModel | null;

  constructor(
    id: string,
    urlHash: string,
    createdAt: string,
    updatedAt: string,
    words: string,
    isPrivate: boolean,
    status: CasualMatchStatus,
    participants: number,
    creator: UserModel,
    language: LanguageModel,
    winner: UserModel | null
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.urlHash = urlHash;
    this.words = words;
    this.isPrivate = isPrivate;
    this.status = status;
    this.participants = participants;
    this.creator = creator;
    this.language = language;
    this.winner = winner;
  }

  public static hydrateFromApi(casualMatchResponse: CasualMatchResponse): CasualMatchModel {
    const creatorModel = UserModel.hydrateFromApi(casualMatchResponse.creator);

    const languageModel = LanguageModel.hydrateFromApi(casualMatchResponse.language);

    const winnerModel = casualMatchResponse.winner
      ? UserModel.hydrateFromApi(casualMatchResponse.winner)
      : casualMatchResponse.winner;

    const status = casualMatchResponse.status as CasualMatchStatus;

    return new CasualMatchModel(
      casualMatchResponse.id,
      casualMatchResponse.urlHash,
      casualMatchResponse.createdAt,
      casualMatchResponse.updatedAt,
      casualMatchResponse.words,
      casualMatchResponse.isPrivate,
      status,
      casualMatchResponse.participants,
      creatorModel,
      languageModel,
      winnerModel
    );
  }
}
