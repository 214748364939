// all values below have an equivalent in the colyseus multiplayer,
// if something is changed here or in the multiplayer,
// the other place has to be adjusted accordingly
// todo: generate with colyseus/schema to create contract? => enums are currently not supported in colyseus/schema
export enum MultiplayerMode {
  GlobalRoom,
  CasualMatch,
  CasualMatchLobby,
  CasualMatchRanked,
}

export enum MultiplayerCode {
  DefaultDisconnect = 4000, // e.g. user closes page
  ConsentedDisconnect = 1005, // is treated like a normal disconnect, at the moment every disconnect is consented, otherwise the reconnect will not work.
  AfkDisconnect = 4001,
  UnexpectedError = 4002,
}

export const multiplayerConfig = {
  [MultiplayerMode.GlobalRoom]: {
    name: 'global-room',
  },
  [MultiplayerMode.CasualMatch]: {
    name: 'casual-match',
    maxClients: 30,
    minClients: 2,
    openLoadingTime: 20_000,
    lockedLoadingTime: 10_000,
    afkWarningTime: 15_000,
    afkLeaveTime: 15_000,
  },
  [MultiplayerMode.CasualMatchLobby]: {
    name: 'casual-match-lobby',
  },
};

export enum CasualMatchStatus {
  Pending = 'pending', // waiting for more players to join
  OpenLoading = 'open-loading', // min players reached, waiting for some more to join
  LockedLoading = 'locked-loading', // player limit reached or openLoading time is up, room is locked and game is starting
  Running = 'running',
  Completed = 'completed',
  Aborted = 'aborted',
}

export enum CasualMatchMessage {
  AfkWarning = 'afk-warning',
  PlayerUpdated = 'player-updated',
  PlayerJoined = 'player-joined',
  PlayerDeleted = 'player-deleted',
  PlayerList = 'player-list',
  ProgressUpdated = 'progress-updated',
  WpmUpdated = 'wpm-updated',
  AccuracyUpdated = 'accuracy-updated',
  ResultCreated = 'result-created',
  PlayerCompleted = 'player-completed',
}

export enum GlobalVisitorsMessage {
  VisitorList = 'visitor-list',
}

export enum CompetitionMessage {
  ResultCreated = 'result-created',
  RefreshList = 'refresh-list',
}
