import * as Colyseus from 'colyseus.js';
import { Room } from 'colyseus.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CasualMatchModel from '@Api/models/CasualMatchModel';

import { CasualMatchState } from '@Api-ws-generated/CasualMatchState';
import { Player } from '@Api-ws-generated/Player';

import { useGlobalContext } from '@Components/context/GlobalContext';
import {
  deleteMultiplayerReconnectionToken,
  getMultiplayerReconnectionToken,
  setMultiplayerReconnectionToken,
} from '@Components/context/LocalStorage';
import {
  CasualMatchMessage,
  CasualMatchStatus,
  MultiplayerCode,
  multiplayerConfig,
  MultiplayerMode,
} from '@Components/helper/colyseus/colyseusHelper';
import { errorToast } from '@Components/helper/toastHelper';
import { Routes } from '@Components/Routes';

import useRouter from '@Helpers/i18n/useRouter';

interface CasualMatchRoom {
  urlHash: string;
}

export const getOrCreateMaxUsersRoom = async () => {
  const client = new Colyseus.Client(process.env.NEXT_PUBLIC_WS_ROOT_URL);

  const rooms = await client.getAvailableRooms<CasualMatchRoom>();

  const filteredRooms = rooms.filter(
    (room) =>
      room.roomId !== multiplayerConfig[MultiplayerMode.GlobalRoom].name &&
      room.name !== multiplayerConfig[MultiplayerMode.CasualMatchLobby].name
  );

  if (filteredRooms.length === 0) {
    return null;
  }

  return filteredRooms.reduce((prev, current) =>
    prev.maxClients - prev.clients < current.maxClients - current.clients && prev.maxClients - prev.clients > 0
      ? prev
      : current
  );
};

export const useCasualMatch = (casualMatch: CasualMatchModel, onPlayerCompleted: () => void) => {
  const config = multiplayerConfig[MultiplayerMode.CasualMatch];

  const client = new Colyseus.Client(process.env.NEXT_PUBLIC_WS_ROOT_URL);
  const [room, setRoom] = useState<Room<CasualMatchState> | null>(null);
  const [players, setPlayers] = useState<Player[]>([]);
  const [status, setStatus] = useState<CasualMatchStatus>(casualMatch.status);
  const [showAfkWarning, setShowAfkWarning] = useState<boolean>(false);
  const [initialLoadingTimeLeft, setInitialLoadingTimeLeft] = useState<number | undefined>(undefined);
  const [unbindListeners, setUnbindListeners] = useState<((() => boolean) | undefined)[]>([]);

  const { user, loggedIn } = useGlobalContext();
  const router = useRouter();
  const { t } = useTranslation('multiplayer');

  const connectToRoom = async () => {
    if (status === CasualMatchStatus.Completed) {
      return;
    }

    const reconnectionToken = getMultiplayerReconnectionToken();

    let room = null;
    if (reconnectionToken) {
      try {
        room = await client.reconnect(reconnectionToken, CasualMatchState);
      } catch (e) {
        deleteMultiplayerReconnectionToken();
      }
    }

    if (room === null) {
      try {
        room = await client.joinOrCreate(
          config.name,
          {
            urlHash: casualMatch.urlHash,
            username: user?.username,
            userId: user?.id,
          },
          CasualMatchState
        );
      } catch (e: unknown) {
        if (e && typeof e === 'object' && 'message' in e) {
          if (e.message === 'casual_match.error.user_already_joined') {
            errorToast(t('casual_match.error.user_already_joined'));
          } else if (e.message === 'casual_match.error.room_locked') {
            errorToast(t('casual_match.error.room_locked'));
          } else {
            errorToast(t('casual_match.error.unexpected'));
          }
        }

        router.push(Routes.Multiplayer);
      }
    }

    if (room) {
      setMultiplayerReconnectionToken(room.reconnectionToken);
    }
    setRoom(room);
  };

  useEffect(() => {
    if (loggedIn) {
      connectToRoom();
    }
  }, [loggedIn]);

  useEffect(() => {
    return () => {
      // consented false is required because otherwise there is a bug in the reconnection.
      // It only works correctly if the leaving of the room happened unintentionally
      // todo: this could be fixed in future colyseus versions, then this should be reconsidered
      room?.leave(false);
    };
  }, [room]);

  useEffect(() => {
    if (!room) {
      return;
    }
    initListeners();
  }, [room, players]);

  const initListeners = () => {
    unbindListeners.forEach((unbindListener) => {
      if (unbindListener) {
        unbindListener();
      }
    });
    setUnbindListeners([]);

    room?.removeAllListeners();

    const unbindStatus = room?.state.listen('status', (status) => {
      setStatus(status as CasualMatchStatus);
    });

    room?.onLeave((code) => {
      switch (code) {
        case MultiplayerCode.DefaultDisconnect:
        case MultiplayerCode.ConsentedDisconnect:
          break;
        case MultiplayerCode.AfkDisconnect:
          //todo: message component instead of toast
          errorToast(t('casual_match.error.afk_disconnect'));
          router.push(Routes.Multiplayer);
          break;
        case MultiplayerCode.UnexpectedError:
          //todo: message component instead of toast
          errorToast(t('casual_match.error.unexpected_error'));
          router.push(Routes.Multiplayer);
          break;
        default:
          throw Error(`Unexpected multiplayer disconnect with code [${code}]`);
      }
    });
    room?.onMessage(CasualMatchMessage.AfkWarning, () => {
      setShowAfkWarning(true);
    });
    room?.onMessage(CasualMatchMessage.PlayerUpdated, (message) => updatePlayers(message));
    room?.onMessage(CasualMatchMessage.PlayerJoined, (message) => setPlayers([...players, message]));
    room?.onMessage(CasualMatchMessage.PlayerDeleted, (removedPlayer) =>
      setPlayers(players.filter((player) => player.id !== removedPlayer.id))
    );
    room?.onMessage(CasualMatchMessage.PlayerList, (message) => {
      setPlayers([...players, ...Object.values<Player>(message.players)]);
      setInitialLoadingTimeLeft(message.loadingTimeLeft);
    });
    room?.onMessage(CasualMatchMessage.PlayerCompleted, () => {
      onPlayerCompleted();
    });

    setUnbindListeners([unbindStatus]);
  };

  const updatePlayers = (newPlayer: Player) => {
    const index = players.findIndex((player) => player.id === newPlayer.id);

    if (index !== -1) {
      players[index] = newPlayer;
      setPlayers([...players]);
    } else {
      setPlayers([...players, newPlayer]);
    }
  };

  return {
    players,
    room,
    status,
    config,
    initialLoadingTimeLeft,
    showAfkWarning,
    setShowAfkWarning,
  };
};
